import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { postSendOtp, postVerifyOtp } from '@utils/api/public/login';
import { storeAccessToken, storeRefreshToken } from '@utils/auth';
import { useTranslation } from 'react-i18next';
import { ChevronLeft } from 'react-bootstrap-icons';
import moment from 'moment';
import { toast } from 'react-toastify';
// import { TurnstileWidget } from '@components/TurnstileWidget';

const Verify = () => {
  const { t } = useTranslation("Verify");

  const [otp, setOtp] = useState('');
  const [correlation, setCorrelation] = useState<string|undefined>();
  const [phone, setPhone] = useState<string|undefined>();
  const [loading, setLoading] = useState(false);
  const [targetTime, setTargetTime] = useState(moment().add(1, "minute"));
  const [currentTime, setCurrentTime] = useState(moment());
  const [turnstileToken, setTurnstileToken] = useState("");

  const enableResend = useMemo(() => targetTime.isBefore(currentTime), [targetTime, currentTime]);
  const timeLeft = useMemo(() => moment.utc(moment.duration(targetTime.diff(currentTime)).asMilliseconds()).format("mm:ss"), [targetTime, currentTime]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(moment()), 1000);

    return () => clearInterval(interval);
  }, []);

  const handleResendOtp = useCallback(() => {
    setLoading(true);
    if (phone) {
      postSendOtp(phone, turnstileToken)
      .then((response) => {
        setOtp(response.token || "");
        setCorrelation(response.correlation);
        setTargetTime(moment().add(1, "minute"));
      })
      .catch(error => {
        toast.error(t("alert.sendOtpError"))
      })
      .finally(() => {
        setLoading(false);
      })
    } else {
      navigate("/");
    }
  }, [phone, t, navigate]);


  const handleVerify = useCallback(() => {
    if (phone && otp && correlation) {
      setLoading(true);
      postVerifyOtp(phone, otp, correlation)
      .then((response) => {
        storeRefreshToken(response.refreshToken, response.refreshTokenExpiryDate);
        storeAccessToken(response.accessToken, response.accessTokenExpiryDate)
        navigate(`/main?token=${response.refreshToken}`);
      })
      .catch(error => {
        toast.error(t("alert.verifyOtpError"))
      })
      .finally(() => {
        setLoading(false);
      })
    }
  }, [correlation, phone, otp, navigate, t]);

  useEffect(() => {
    setPhone(location.state?.phone);
    setCorrelation(location.state?.correlation);
    setOtp(location.state?.token || "");
  }, [location]);

  const handleBack = useCallback(() => {
    navigate("/")
  }, [navigate]);

  return ( <>
      <div className="topbar">
        <Container style={{maxWidth:500}}>
          <Row style={{marginRight:0}}>
          <Col style={{justifyContent: "flex-start", display:"flex"}}>
              <Button onClick={handleBack} style={{paddingLeft:0}}>
                <ChevronLeft size={20}/>
              </Button>
            </Col>
            <Col xs={6}><span className="text-center" style={{ fontSize: "18px", fontWeight: "bold" }}>{t("header")}</span></Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
      <Container style={{maxWidth:500}}>
        <Row className='text-center mt-5'>
          <Col className='my-4'><h2>{t("title")}</h2></Col>
        </Row>
      <Form onSubmitCapture={(e) => {
        e.preventDefault();
        handleVerify();
      }}>
        <Row className='mb-4'>
          <Col>
          <Form.Control
            inputMode="numeric"
            maxLength={6}
            placeholder={t("textfield.otp")}
            className='p-1 text-center'
            style={{ fontSize: "xx-large", fontWeight: "bold" }}
            value={otp}
            onChange={e => setOtp(e.target.value)}
          />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button className='p-3 w-100' variant='danger' disabled={loading || !enableResend} onClick={handleResendOtp}>
              <div>
              {enableResend ? 
                <strong> {t("button.resend")}</strong>
                :
                <strong style={{fontSize:"13px"}}>{t("button.resendCoolingDown", { timeLeft })}</strong>
              }
              </div>
            </Button>
          </Col>
          <Col>
            <Button className='p-3 w-100' variant='danger' disabled={loading || otp.length !== 6} onClick={handleVerify}>
              <div><strong>{loading ? t("button.verifying") : t("button.confirm")}</strong></div>
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>

    {/* {TurnstileWidget((token) => {
      setTurnstileToken(token);
    })} */}
  </>
    
  );
}
 
export default Verify;