import { isAxiosError } from "axios";
import { publicApi } from "./api";

export function storeRefreshToken(refreshToken:string, expiryDate:string) {
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("refreshTokenExpiryDate", expiryDate);
}

export function storeAccessToken(accessToken:string, expiryDate:string) {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("accessTokenExpiryDate", expiryDate);
}

export function getAccessToken() {
  return localStorage.getItem("accessToken");
}

export async function refreshAccessToken() {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    return false;
  }

  try {
    const response = await publicApi.post("/login/refresh", {
      refreshToken
    });

    storeAccessToken(response.data.accessToken, response.data.accessTokenExpiryDate);

    return true;
  } catch (error:any) {
    console.error(error);
    if (isAxiosError(error) && error.response?.status === 401) {
      logout();
    }
    return false;
  }
}

export function logout() {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("accessTokenExpiryDate");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("refreshTokenExpiryDate");

  window.location.href = "/";
}