import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import i18nHttpBackend from 'i18next-http-backend';

export const i18n = i18next
.use(initReactI18next)
.use(i18nHttpBackend)
.init({
  lng: new URLSearchParams(window.location.search).get("locale") || localStorage.getItem("locale") || undefined,
  fallbackLng: 'tc',
  fallbackNS: ["translation"],
  returnNull: false,
  backend: {
    loadPath: "/locales/{{lng}}/{{ns}}.json"
  },
  react: {
    useSuspense: false
  }
});