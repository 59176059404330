import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Row, Col, Stack, ListGroup } from 'react-bootstrap';
import dollar from './Kcoin_logo.png'
import './index.css'
import { getTxnHistory } from '@utils/api/private/history';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getUser } from '@utils/api/private/user';


export type Props = {
  showHistory: boolean,
  handleClose: () => void
};

const PointHistory = ({ showHistory, handleClose }: Props) => {
  const { t } = useTranslation("PointHistory");

  const [userId, setUserId] = useState<number|undefined>();

  const [histories, setHistories] = useState<{
    type: string
    txnTime: string
    route: string
    amount: string
    routeMap?: {
    name: string
    company: string
    }
    concession: boolean
  }[]>([]);

  const handleLoadHistories = useCallback(() => {
    getTxnHistory().then(data => {
      setHistories(data)
    });
  }, []);

  useEffect(() => {
    if (showHistory) {
      handleLoadHistories();
    }
  }, [handleLoadHistories, showHistory]);

  const records = useMemo(() => histories.map(history => {
    let title = t(`txn.${history.type || "others"}`, {
      company: history.routeMap?.company ? t(`busCompany.${history.routeMap?.company}`) : "",
      route: history.routeMap?.name || history.route
    })
    return {
      title,
      time: moment(history.txnTime).format("YYYY-MM-DD HH:mm"),
      points: history.amount,
      concession: history.concession,
      type: history.type
    }
  }), [histories, t]);

  useEffect(() => {
    getUser().then(data => {
      setUserId(data.id);
    })
  }, []);

  return (
    <Modal show={showHistory} onHide={handleClose} size={'lg'} centered dialogClassName='points'>
      <Modal.Header closeButton>
        <Stack>
          <Modal.Title>{t("title")}</Modal.Title>
          {userId && <div style={{fontSize: 17}} className=''>{t("userId", { userId })}</div>}
          <div style={{fontSize:"25px", color:"grey", fontWeight:"bolder"}}>{t("recent10Entries")}</div>
        </Stack>
      </Modal.Header>
      <Modal.Body style={{ overflowY: "scroll", paddingTop:0 }}>
        <ListGroup variant='flush'>
          {records.map((record, i) => <ListGroup.Item key={i}>
          <Row>
            <Col className='text-start col-7'>
              <Row>
              {record.title} {record.concession && record.type ==='bus' && "(小童/長者)"}
              </Row>
              <Row style={{ color:"grey", fontSize:"14px" }}>
              {record.time}
              </Row>
            </Col>
            <Col className='text-end justify-content-center col-5' style={ Number(record.points) > 0 ? { color:'green'} : {color:'#d10000'}}>
            <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
              {Number(record.points) > 0 ? "+": "-"} 
              {Math.abs(Number(record.points)).toFixed(2)}
            </div>
            </Col>
          </Row>
          </ListGroup.Item>)}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}

export default PointHistory;