import { PurchaseDayPassButton } from '@components/PurchaseDayPassButton';
import TermsOfUse from '@components/TermsOfUse';
import { postRedeem } from '@utils/api/public/login';
import { storeAccessToken, storeRefreshToken } from '@utils/auth';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-maskedinput';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import "./index.css";

const RedeemCode = () => {
  const navigate = useNavigate();

  const { t } = useTranslation("RedeemCode");
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [redeemCode, setRedeemCode] = useState("");
  const [failoverMode, setFailoverMode] = useState(false);

  const plainRedeemCode = useMemo(() => redeemCode.replace(/-/g, ""), [redeemCode]);
  const disabled = useMemo(() => loading || !(agreedTerms && plainRedeemCode.length === 16), [loading, agreedTerms, plainRedeemCode]);

  useEffect(() => {
    const value = searchParams.get("code");
    if (value) {
      setRedeemCode(value);
    }
  }, [searchParams]);

  const handleSwitchToFailoverMode = useCallback(() => {
    setFailoverMode(true);
    setRedeemCode(oldRedeemCode => oldRedeemCode.replaceAll(/\D/g, ""));
  }, []);

  const handleRedeem = useCallback(() => {
    setLoading(true);
    
    const appId = localStorage.getItem("appId") || "test";
    const currentAccessToken = localStorage.getItem("accessToken") || undefined;

    postRedeem(appId, plainRedeemCode, currentAccessToken)
    .then((response) => {
      storeRefreshToken(response.refreshToken, response.refreshTokenExpiryDate);
      storeAccessToken(response.accessToken, response.accessTokenExpiryDate)
      navigate(`/main?token=${response.refreshToken}`);
    })
    .catch(error => {
      toast.error(t(`error.${error.response?.data?.message || error.message}`));
    })
    .finally(() => {
      setLoading(false);
    })
  }, [plainRedeemCode]);

  return <>
    <div className="topbar">
      <Container>
        <Row>
          <Col xs={8}>
            <span className="appbar-title" style={{ width: "100%" }}>{t("appbar.title")}</span>
          </Col>
          <Col style={{ textAlign: "right", alignContent: "center" }}>
            <a className="back" href={`/?token=${localStorage.getItem("refreshToken")}`}><span>✕</span></a>
          </Col>
        </Row>
      </Container>
    </div>
    <Container style={{maxWidth:500}}>
      <Row className='text-center mt-5'>
        <Col className='my-4'><h2>{t("title")}</h2></Col>
      </Row>
      <Row className='mb-4 text-center'>
        <Col>
          <Form.Group>
            <Form.Control
              as={failoverMode ? undefined : MaskedInput}
              style={{ textAlign: "center", fontSize: "1.2em" }}
              mask="1111-1111-1111-1111"
              inputMode="numeric"
              placeholder={t(failoverMode?"textfield.redeemCodeFailover":"textfield.redeemCode")}
              className='p-3'
              value={redeemCode}
              onChange={(e) => setRedeemCode(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter" && !(loading || plainRedeemCode.length !== 16)) {
                  handleRedeem();
                }
              }}
            />
            {!failoverMode && <div>
              <a href="#" onClick={handleSwitchToFailoverMode}>{t("failoverLink")}</a>
            </div>}
            <Form.Check
              checked={agreedTerms}
              onChange={(e) => {
                setAgreedTerms(e.target.checked);
              }}
              inline={true}
              className="mt-4"
            />
            <>{t('terms1')}</><a className='terms' onClick={() => setShowTerms(true)}>{t('terms2')}</a>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className='w-100'
            variant='danger'
            onClick={handleRedeem}
            disabled={disabled}
          >
            <div><strong>{t("button.redeem")}</strong></div>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <PurchaseDayPassButton/>
        </Col>
      </Row>
    </Container>
    <TermsOfUse showTerms={showTerms} handleClose={() => setShowTerms(false)}/>
  </>
}
 
export default RedeemCode;