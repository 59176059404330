import { useNavigate } from "react-router-dom";
import './index.css';
import { useTranslation } from 'react-i18next';
import { Button, Container, Spinner } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { refreshAccessToken } from "@utils/auth";
import axios from "axios";
import { RedeemPurchaseDayPassButton } from "@components/RedeemPurchaseDayPassButton";

const Landing = () => {
  const { t } = useTranslation("Landing");
  const navigate = useNavigate();

  const [loginChecked, setLoginChecked] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const handleGetImageSrc = useCallback(() => {
    const locale = localStorage.getItem("locale") || "tc";
    // axios.get("https://app.kmb.hk/app1933/eCoin/php/get_image.php", { params: { locale }})
    // .then(({ data }) => {
    //   setImageSrc(data);
    // });
    setImageSrc(`https://app.kmb.hk/app1933/eCoin/image/register_club1933_${locale}.jpg`)
  }, []);

  useEffect(() => {
    refreshAccessToken().then(result => {
      setLoginChecked(true);
      if (result) {
        navigate(`/main?token=${localStorage.getItem("refreshToken")}`)
      }
    });
  }, []);

  useEffect(handleGetImageSrc, [handleGetImageSrc]);

  if (!loginChecked) {
    return <>
      <div className="topbar login">
        <Container className='topbarcontainer' style={{ maxWidth:500 }}>
          <span className="text-center" style={{ fontSize: "18px", fontWeight: "bold" }}>{t("appbar.title")}</span>
        </Container>
      </div>
      <Container style={{ alignItems: "center", textAlign: "center", maxWidth:500 }}>
        <div style={{ marginTop: 20 }}>
          <Spinner animation="border" variant="danger" role="status"/>
        </div>
      </Container>
    </>
  }

  return <>
    <div className="topbar login">
      <Container className='topbarcontainer' style={{ maxWidth:500 }}>
        <span className="text-center" style={{ fontSize: "18px", fontWeight: "bold" }}>{t("appbar.title")}</span>
      </Container>
    </div>
    <Container style={{ alignItems: "center", maxWidth:500 }}>
      {imageSrc && <img style={{ maxWidth: "100%" }} src={imageSrc}/>}
      <RedeemPurchaseDayPassButton/>
    </Container>
  </>
}
 
export default Landing;