import { publicApi } from "..";

export async function postSendOtp(phone:string, turnstileToken:string) {
  const result = await publicApi.post("/login/sendOtp", {
    phone,
    turnstileToken
  });

  return result.data;
}

export async function postVerifyOtp(phone:string, token:string, correlation:string) {
  const result = await publicApi.post("/login/verifyOtp", {
    phone,
    token,
    correlation
  });

  return result.data;
}

export async function postRedeem(appId:string, redeemCode:string, currentAccessToken?:string) {
  const result = await publicApi.post("/login/redeem", {
    appId,
    redeemCode,
    currentAccessToken
  });

  return result.data;
}