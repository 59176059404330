import { postUserSwitchMode } from "@utils/api/private/user";
import { storeAccessToken, storeRefreshToken } from "@utils/auth";
import { useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

type Props = {
  currentMode: "user" | "pass"
  afterLoad: () => any
}

export function SwitchModeButton({ afterLoad, currentMode }:Props) {
  const { t } = useTranslation("SwitchModeButton");
  
  const [loading, setLoading] = useState(false);

  const handleLoad = useCallback(() => {
    const appId = localStorage.getItem("appId") || "test";

    setLoading(true);
    postUserSwitchMode(appId)
    .then((response) => {
      storeRefreshToken(response.refreshToken, response.refreshTokenExpiryDate);
      storeAccessToken(response.accessToken, response.accessTokenExpiryDate);
      afterLoad();
    })
    .catch((error) => {
      toast.error(t(`error.${error.response?.data?.message || error.message}`));
    })
    .finally(() => {
      setLoading(false);
    })
  }, [t, afterLoad]);

  return <Button style={{ width: "100%" }} disabled={loading} onClick={handleLoad}>{t(currentMode)}</Button>
}