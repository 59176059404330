import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import Login from './containers/Login';
import Verify from './containers/Verify';
import Main from './containers/Main';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RedeemCode from './containers/RedeemCode';
import Landing from './containers/Landing';
import { ParamsToLocalStorage } from "./components/ParamsToLocalStorage";

function Layout() {
  return <>
    <ParamsToLocalStorage/>
    <Outlet/>
  </>
}

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "/redeem",
        element: <RedeemCode />,
      },
      {
        path: "/verify",
        element: <Verify />,
      },
      {
        path: "/main",
        element: <Main />
      }
    ]
  }
]);

function App() {
  return (<>
    <RouterProvider router={router}/>
    <ToastContainer />
  </>
  );
}

export default App;
